import axios from "axios";
import { Bus } from "@/utils/Bus.js";

var read_access_token = "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI4Y2E4NWEyOTU2NTJjNzU1MDg2NzBmZDRhYTY5MDdjZSIsInN1YiI6IjVkNzJhZTY3M2E0YTEyMDAxMGQwZjIxNCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.WyL9H79I3pdJL5G0NoW9lZlrL5yTaUBhQ8IFHKftdqA";
const api = axios.create({
    baseURL: "https://api.themoviedb.org/3/",
    headers: {
        "Authorization": "Bearer " + read_access_token,
        "Content-Type": "application/json;charset=utf-8"
    }
});

class TMDb {

    process(query = "", page = 1, filter = "all") {
        if (query == "") {
            switch (filter) {
                case "all":
                    return this.getPopular(page)
                    break
                case "movies":
                    return this.getPopularMovies(page)
                    break;
                case "tv":
                    return this.getPopularTv(page)
                    break;
                case "people":
                    return this.getPopularPeople(page)
                    break
            }
        }
        else if (query != null) {
            switch (filter) {
                case "all":
                    return this.search(query, page);
                    break
                case "movies":
                    return this.searchMovies(query, page);
                    break;
                case "tv":
                    return this.searchTv(query, page);
                    break;
                case "people":
                    return this.searchPeople(query, page);
                    break
            }
        }
        else {
            return [];
        }
    }

    getPopular(page = 1) {
        return api.get(
            "/trending/all/day?api_key=8ca85a295652c75508670fd4aa6907ce&page=" + page
        );
    }

    getMovieBackdropWithText(id) {
        return api.get('/movie/' + id + '/images?api_key=8ca85a295652c75508670fd4aa6907ce&language=en')
    }

    getTvBackdropWithText(id) {
        return api.get('/tv/' + id + '/images?api_key=8ca85a295652c75508670fd4aa6907ce&language=en')
    }

    getMovie(id) {
        return api.get("movie/" +
            id +
            "?api_key=8ca85a295652c75508670fd4aa6907ce&append_to_response=release_dates,similar,credits,videos")
    }

    getMovieRecommendations(page = 1) {
        return this.createV4api().get('account/' + this.getAccountId() + '/movie/recommendations?page=' + page)
    }

    getTvRecommendations(page = 1) {
        return this.createV4api().get('account/' + this.getAccountId() + '/tv/recommendations?page=' + page)
    }

    getTv(id) {
        return api.get("tv/" +
            id +
            "?api_key=8ca85a295652c75508670fd4aa6907ce&append_to_response=release_dates,episodes,similar,credits,videos")
    }

    getTvSeason(id, season) {
        return api.get("tv/" + id + "/season/" + season + "?api_key=8ca85a295652c75508670fd4aa6907ce");
    }

    async getMovieCertification(id) {
        var certification = await api.get(
            "movie/" +
            id +
            "?api_key=8ca85a295652c75508670fd4aa6907ce&append_to_response=release_dates"
        );
        //console.log(certification);
        let cert = "NR";
        certification.data.release_dates.results.forEach(country => {
            if (country.iso_3166_1 == "US") {
                country.release_dates.forEach(date => {
                    if (date.certification != "") {
                        cert = date.certification;
                    }
                });
            }
        });
        return cert;
    }

    async getTvCertification(id) {
        var certification = await api.get(
            "/tv/" + id + "/content_ratings?api_key=8ca85a295652c75508670fd4aa6907ce"
        );
        var cert = "NR";
        certification.data.results.forEach(country => {
            if (country.iso_3166_1 == "US") {
                cert = country.rating;
            }
        });
        return cert;
    }

    search(query, page = 1) {
        return api.get(
            "/search/multi?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&query=" +
            query +
            "&include_adult=false&page=" + page
        );
    }

    searchPeople(query, page = 1) {
        return api.get(
            "/search/person?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&query=" +
            query +
            "&include_adult=false&page=" + page
        );
    }

    searchMovies(query, page = 1) {
        return api.get(
            "/search/movie?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&query=" +
            query +
            "&include_adult=false&page=" + page
        );
    }

    searchTv(query, page = 1) {
        return api.get(
            "/search/tv?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&query=" +
            query +
            "&include_adult=false&page=" + page
        );
    }

    getPopularPeople(page = 1) {
        return api.get('https://api.themoviedb.org/3/person/popular?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getMovieReviews(id) {
        return api.get("movie/" + id + "/reviews?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=1")
    }

    getMovieCollection(id) {
        return api.get("collection/" + id + "?api_key=8ca85a295652c75508670fd4aa6907ce")
    }

    getTvReviews(id) {
        return api.get("tv/" + id + "/reviews?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=1")
    }

    //Setup user login
    requestToken(redirect) {
        //return api.get('https://api.themoviedb.org/3/authentication/token/new?api_key=8ca85a295652c75508670fd4aa6907ce')
        return api.post('https://api.themoviedb.org/4/auth/request_token?redirect_to=' + redirect + '&api_key=8ca85a295652c75508670fd4aa6907ce');
    }

    generateAccessToken(req_token) {
        return api.post('https://api.themoviedb.org/4/auth/access_token?api_key=8ca85a295652c75508670fd4aa6907ce',
            {
                "request_token": req_token
            })
    }

    getMovieWatchlist(page = 1) {
        const v4api = this.createV4api()
        return v4api.get('account/' + this.getAccountId() + '/movie/watchlist?page=' + page)
    }

    getTvWatchlist(page = 1) {
        const v4api = this.createV4api()
        return v4api.get('account/' + this.getAccountId() + '/tv/watchlist?page=' + page)
    }

    processWatchlist(id, type, add) {
        return api.post('account/' + id + '/watchlist?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId(),
            {
                "media_type": type,
                "media_id": id,
                "watchlist": add
            }) //add is either true (add to list) or false (remove from list)
    }
    removeFromMovieWatchlist(id) {
        this.processWatchlist(id, "movie", false)
    }

    removeFromTvWatchlist(id) {
        this.processWatchlist(id, "tv", false)
    }

    getMovieFavorites(page = 1) {
        const v4api = this.createV4api()
        return v4api.get('account/' + this.getAccountId() + '/movie/favorites?page=' + page)
    }

    getTvFavorites(page = 1) {
        const v4api = this.createV4api()
        return v4api.get('account/' + this.getAccountId() + '/tv/favorites?page=' + page)
    }
    removeFromMovieFavorites(id) {
        this.processFavorites(id, "movie", false)
    }

    removeFromTvFavorites(id) {
        this.processFavorites(id, "tv", false)
    }
    processFavorites(id, type, add) {
        return api.post('account/' + id + '/favorite?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId(),
            {
                "media_type": type,
                "media_id": id,
                "favorite": add
            }) //add is either true (add to list) or false (remove from list)
    }

    getAllPersonData(id) {
        return api.get('/person/' + id + '?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&append_to_response=movie_credits,external_ids,images,tv_credits,combined_credits')
    }

    getMovieGenres() {
        return api.get('genre/movie/list?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US')
    }

    getTvGenres() {
        return api.get('genre/tv/list?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US')
    }

    getMovieCertifications() {
        return api.get('certification/movie/list?api_key=8ca85a295652c75508670fd4aa6907ce');
    }

    getTvCertifications() {
        return api.get('certification/tv/list?api_key=8ca85a295652c75508670fd4aa6907ce');
    }

    discoverMovie(params) {
        return api.get('/discover/movie', {
            params: params
        })
    }

    discoverTv(params) {
        return api.get('/discover/tv', {
            params: params
        })
    }

    getNowPlayingMovies(page = 1) { //
        return api.get('movie/now_playing?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getPopularMovies(page = 1) { //
        return api.get('movie/popular?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getTopRatedMovies(page = 1) { //
        return api.get('movie/top_rated?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getUpcomingMovies(page = 1) { //
        return api.get('movie/upcoming?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getTrendingMovies(page = 1) { //
        return api.get("trending/movie/day?api_key=8ca85a295652c75508670fd4aa6907ce&page=" + page)
    }

    getTrendingTv(page = 1) { //
        return api.get("trending/tv/day?api_key=8ca85a295652c75508670fd4aa6907ce&page=" + page)
    }

    getTvAiringToday(page = 1) {
        return api.get('tv/airing_today?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getTvOnTheAir(page = 1) { //
        return api.get('tv/on_the_air?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getPopularTv(page = 1) { //
        return api.get('tv/popular?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getTopRatedTv(page = 1) { //
        return api.get('tv/top_rated?api_key=8ca85a295652c75508670fd4aa6907ce&language=en-US&page=' + page)
    }

    getMovieAccountStates(id) {
        return api.get('movie/' + id + '/account_states?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId())
    }

    getTvAccountStates(id) {
        return api.get('tv/' + id + '/account_states?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId())
    }

    rateMovie(rating, id) {
        return api.post('movie/' + id + '/rating?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId(),
            {
                'value': Number(rating)
            }) //After movies are rated they are removed from the watchlist by tmdb. This can be changed in settings
    }

    rateTv(rating, id) {
        return api.post('tv/' + id + '/rating?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId(),
            {
                'value': Number(rating)
            }) //After tv shows are rated they are removed from the watchlist by tmdb. This can be changed in settings
    }

    deleteMovieRating(id) {
        return api.delete('movie/' + id + '/rating?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId());
    }

    deleteTvRating(id) {
        return api.delete('tv/' + id + '/rating?api_key=8ca85a295652c75508670fd4aa6907ce&session_id=' + this.getSessionId());
    }


    createV4api() {
        return axios.create({
            baseURL: "https://api.themoviedb.org/4/",
            headers: {
                Authorization: "Bearer " + this.getAccessToken(),
                "Content-Type": "application/json;charset=utf-8"
            }
        });
    }


    wait(timeout) {
        return new Promise(resolve => {
            setTimeout(resolve, timeout);
        });
    }

    storeData(account_id, access_token) {
        localStorage.setItem('account_id', account_id);
        localStorage.setItem('access_token', access_token);
    }

    getAccountId() {
        return localStorage.getItem('account_id');
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }

    getSessionId() {
        return localStorage.getItem('session_id');
    }

    generateRedirectPath(token) {
        return encodeURI("https://phlix.site/#/signin"); //TODO: Change this to the final url of the app. Currently tinyurl = localhost:8080/#/signin
    }

    setup() {
        if (this.getAccountId() && this.getAccessToken() != null) alert('Already logged in')
        else {
            var tmdb = this;
            tmdb.requestToken(tmdb.generateRedirectPath()).then(resp => {
                console.log("Request token: " + resp.data.request_token)
                localStorage.setItem('request_token', resp.data.request_token);
                window.location.href =
                    "https://www.themoviedb.org/auth/access?request_token=" +
                    resp.data.request_token;


            });
        }
    }

    isSignedIn() {
        return (this.getAccountId() && this.getAccessToken() != null)
    }

    convertSession() {
        let access_token = this.getAccessToken();
        api.post('https://api.themoviedb.org/3/authentication/session/convert/4?api_key=8ca85a295652c75508670fd4aa6907ce', {
            "access_token": access_token
        }).then(resp => {
            console.log(resp.data.session_id);
            localStorage.setItem('session_id', resp.data.session_id);
            window.location = "/"
        })
    }

    logoff() {
        //Actually deleting the access tokens doesn't work, so clearing the keys from localstorage is the next best thing
        // let access_token = this.getAccessToken();
        // axios.create({
        //     headers: {
        //         Authorization: "Bearer " + read_access_token,
        //         "Content-Type": "application/json;charset=utf-8"
        //     }
        // }).delete('https://api.themoviedb.org/4/auth/access_token', {
        //     "access_token": access_token
        // }).then(resp => console.log(resp));        

        // access_token = null;

        localStorage.clear();
        Bus.$emit('signin');
        Bus.$emit('signedoff');
    }
}


export default TMDb;